import { useCallback, createRef, useState, FC } from 'react'
import cn from 'classnames'

import scrollToElement from 'utils/scroll-to-element'
import useViewport from 'libs/use-viewport'

import styles from './styles.module.scss'
import { useTranslation } from 'utils/use-translation'

const videosUrl = [
  'https://res.cloudinary.com/coinrabbit/video/upload/v1609347206/1___pvwty5.mp4',
  'https://res.cloudinary.com/coinrabbit/video/upload/v1609347206/2___ftrovs.mp4',
  'https://res.cloudinary.com/coinrabbit/video/upload/v1609347206/4___u38lhw.mp4',
  'https://res.cloudinary.com/coinrabbit/video/upload/v1609347206/3___wsxuix.mp4',
]

export const HowToBorrow: FC = () => {
  const { t } = useTranslation()

  const [viewport] = useViewport()
  const [activeTab, setActiveTab] = useState(0)
  const [closedTab, setClosedTab] = useState<number>(null)

  const videosRefs = [
    createRef<HTMLVideoElement>(),
    createRef<HTMLVideoElement>(),
    createRef<HTMLVideoElement>(),
    createRef<HTMLVideoElement>(),
  ]

  const videos = videosUrl.map((item, index) => {
    return (
      <video
        width="676"
        height="547"
        ref={videosRefs[index]}
        className={cn(styles.videoItem, { [styles.videoItemActive]: activeTab === index })}
        preload="none"
        autoPlay={activeTab === index}
        loop
        muted
        key={`video${index}${item}`}
      >
        <source src={item} type="video/mp4" />
      </video>
    )
  })

  const toggleTab = useCallback(
    (id: number) => {
      return function () {
        setClosedTab(activeTab !== id ? activeTab : id)
        setActiveTab(activeTab !== id ? id : 0)
      }
    },
    [activeTab, closedTab, videosRefs],
  )

  const handleScrollToHeader = useCallback(() => {
    scrollToElement('header')
  }, [])

  return (
    <div id="how-it-works" className={styles.block}>
      <div className={cn('container', styles.blockWrapper)}>
        <div className={styles.content}>
          <h2 className={styles.title}>{t('how_to_borrow_crypto_capital')}</h2>
          <p className={styles.subheadingText}>{t('we_make_it_easy_get_manage_loan')}</p>
          <div className={styles.guideListWrapper}>
            <div className={styles.guideList}>
              <div className={cn(styles.tab, { [styles.tabActive]: activeTab === 0 })}>
                <div className={cn(styles.number, { [styles.openedTabNumber]: activeTab === 0 })}>
                  <span onClick={toggleTab(0)}>1</span>
                  <div />
                </div>
                <div className={styles.tabContent}>
                  <h3 onClick={toggleTab(0)}>{t('calculate_crypto_loan')}</h3>
                  <p className={cn({ [styles.openedTab]: activeTab === 0 }, { [styles.closedTab]: closedTab === 0 })}>
                    {t('calculate_crypto_loan_using_options')}
                  </p>
                </div>
              </div>

              <div className={cn(styles.tab, { [styles.tabActive]: activeTab === 1 })}>
                <div className={cn(styles.number, { [styles.openedTabNumber]: activeTab === 1 })}>
                  <span onClick={toggleTab(1)}>2</span>
                  <div />
                </div>
                <div className={styles.tabContent}>
                  <h3 onClick={toggleTab(1)}>{t('get_loan_within_minutes')}</h3>
                  <p className={cn({ [styles.openedTab]: activeTab === 1 }, { [styles.closedTab]: closedTab === 1 })}>
                    {t('send_collateral_receive_loan')}
                  </p>
                </div>
              </div>

              <div className={cn(styles.tab, { [styles.tabActive]: activeTab === 2 })}>
                <div className={cn(styles.number, { [styles.openedTabNumber]: activeTab === 2 })}>
                  <span onClick={toggleTab(2)}>3</span>
                  <div />
                </div>
                <div className={styles.tabContent}>
                  <h3 onClick={toggleTab(2)}>{t('start_spending_step')}</h3>
                  <p className={cn({ [styles.openedTab]: activeTab === 2 }, { [styles.closedTab]: closedTab === 2 })}>
                    {t('use_loan')}
                  </p>
                </div>
              </div>

              <div className={cn(styles.tab, { [styles.tabActive]: activeTab === 3 })}>
                <div className={cn(styles.number, { [styles.openedTabNumber]: activeTab === 3 })}>
                  <span onClick={toggleTab(3)}>4</span>
                </div>
                <div className={styles.tabContent}>
                  <h3 onClick={toggleTab(3)}>{t('get_back_collateral_step')}</h3>
                  <p className={cn({ [styles.openedTab]: activeTab === 3 }, { [styles.closedTab]: closedTab === 3 })}>
                    {t('pay_collateral_back')}
                  </p>
                </div>
              </div>
            </div>
            <button className={styles.button} type="button" onClick={handleScrollToHeader}>{t('get_loan')}</button>
          </div>
        </div>
        {(viewport === 'desktop' || viewport === 'tablet') && (
          <div className={styles.videoBlock}>{videos[activeTab]}</div>
        )}
      </div>
    </div>
  )
}
