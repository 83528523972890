import SwiperBlock from 'components/ui/swiper'

import { useTranslation } from 'utils/use-translation'

import styles from './styles.module.scss'
import { slides } from './assets/slides'

export default function SwiperComponent() {
  const { t } = useTranslation()

  return (
    <div className={styles.wrapper}>
      <SwiperBlock title={t('crypto_community_trusts_us')} slides={slides} />
    </div>
  )
}
