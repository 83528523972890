import { FC } from 'react'

import BuyMoreCryptoIcon from 'images/why-loans-needed/buy-more-crypto-icon.svg'
import OptimizeTaxesIcon from 'images/why-loans-needed/optimize-taxes.svg'
import InvestInYourBusinessIcon from 'images/why-loans-needed/invest-in-your-business.svg'
import MakeHugePurchaseIcon from 'images/why-loans-needed/make-a-huge-purchase.svg'

import styles from './styles.module.scss'
import { useTranslation } from 'utils/use-translation'

export const WhyLoansNeeded: FC = () => {
  const { t } = useTranslation()

  return (
    <div className={styles.whyLoanNeeded}>
      <div className={styles.title}>
        <p>{t('one_crypto_loan')}</p>
      </div>
      <div className={styles.description}>
        <p>{t('get_most_value_from_crypto')}</p>
      </div>
      <div className={styles.cardList}>
        <div className={styles.cardItem}>
          <div className={styles.cardItemHead}>
            <div className={styles.cardItemIcon}>
              <img src={BuyMoreCryptoIcon} alt="Buy more crypto block" />
            </div>
            <div className={styles.cardItemTitle}>
              <p>{t('buy_crypto')}</p>
            </div>
          </div>
          <div className={styles.cardItemDescription}>
            <p>
              {t('keep_holding_crypto_get_liquidity')}
            </p>
          </div>
        </div>
        <div className={styles.cardItem}>
          <div className={styles.cardItemHead}>
            <div className={styles.cardItemIcon}>
              <img src={OptimizeTaxesIcon} alt="Optimize taxes block" />
            </div>
            <div className={styles.cardItemTitle}>
              <p>{t('optimize_taxes_title')}</p>
            </div>
          </div>
          <div className={styles.cardItemDescription}>
            <p>
              {t('maximize_tax_efficiency')}
            </p>
          </div>
        </div>
        <div className={styles.cardItem}>
          <div className={styles.cardItemHead}>
            <div className={styles.cardItemIcon}>
              <img src={MakeHugePurchaseIcon} alt="Optimize taxes block" />
            </div>
            <div className={styles.cardItemTitle}>
              <p>{t('make_huge_purchases')}</p>
            </div>
          </div>
          <div className={styles.cardItemDescription}>
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            <p>
              {t('leverage_crypto')}
            </p>
          </div>
        </div>
        <div className={styles.cardItem}>
          <div className={styles.cardItemHead}>
            <div className={styles.cardItemIcon}>
              <img src={InvestInYourBusinessIcon} alt="Invest in your business block" />
            </div>
            <div className={styles.cardItemTitle}>
              <p>{t('invest_in_your_business')}</p>
            </div>
          </div>
          <div className={styles.cardItemDescription}>
            <p>{t('reinvest_make_company_grow')}</p>
            <p>{t('cover_operational_expenses')}</p>
          </div>
        </div>
      </div>
    </div>
  )
}
