import { ReactElement, useEffect, useRef, useState } from 'react'
import Link from 'next/link'

import { useAppSelector } from 'store/hooks'
import { getCurrencies } from 'store/reducers/currencies'
import { coinPagesList } from 'config/coin-pages-list'
import useViewport from 'libs/use-viewport'

import ButtonBlue from 'components/ui/button/_color/_blue'

import cn from 'classnames'

import styles from './styles.module.scss'
import { useTranslation } from 'utils/use-translation'

const defaultCoinPagesList: ReactElement[] = Object.values(coinPagesList).map((el) => (
  <Link key={`${el.code}-${el.network}-default`} prefetch={false} href={`/loans/${el.coinPageUrl}`}>
    <a className={styles.coinButton}>
      <img className={styles.coinIcon} alt={el.code} width={24} height={24} src={el.logoUrl} />
      <p>{el.name}</p>
      <span>{el.code.toUpperCase()}</span>
    </a>
  </Link>
))

export default function SupportedCollateral() {
  const { t } = useTranslation()  
  const currencies = useAppSelector(getCurrencies)
  const isMounted = useRef(true)
  const [viewport] = useViewport()
  const countOfVisibleTokens = viewport.includes('mobile') ? 18 : 36

  const [renderedList, setRenderedList] = useState<ReactElement[]>(defaultCoinPagesList)
  const [renderedListByDefault, setRenderedListByDefault] = useState<ReactElement[]>(defaultCoinPagesList)
  const [hidden, setHidden] = useState(false)

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  useEffect(() => {
    const updateRenderList = async () => {
      const supportedCurrencies = currencies.filter((el) => el.is_loan_deposit_enabled)

      if (supportedCurrencies.length === 0) {
        return
      }

      const newRenderedList = supportedCurrencies
        .sort((a, b) => a.loan_deposit_priority - b.loan_deposit_priority)
        .map((el) => {
          const key = `${el.code}-${el.network}`.toLowerCase()
          const base = (
            <>
              <img
                loading={'lazy'}
                className={styles.coinIcon}
                alt={el.code}
                width={24}
                height={24}
                src={el.logo_url}
              />
              <p>{el.name}</p>
              <span>{el.code.toLowerCase()}</span>
            </>
          )

          const coinPageInfo = coinPagesList[key]
          if (coinPageInfo) {
            return (
              <Link key={`${key}-link`} prefetch={false} href={`/loans/${coinPageInfo.coinPageUrl}`}>
                <a className={styles.coinButton}>{base}</a>
              </Link>
            )
          } else {
            return (
              <button key={`${key}-button`} type="button" className={styles.coinButton}>
                {base}
              </button>
            )
          }
        })

      if (newRenderedList.length > 0) {
        if (isMounted.current) {
          setRenderedList(newRenderedList)
          setRenderedListByDefault(newRenderedList.slice().splice(0, countOfVisibleTokens))
        }
      }
    }

    void updateRenderList()
  }, [currencies])

  const handleMoreCoins = (coins) => {
    setRenderedListByDefault(renderedListByDefault.concat(coins))
  }

  return (
    <div className={styles.block}>
      <div className={'container'}>
        <h2 className={styles.title}>{t('crypto_collateral_loans_first_capital')}</h2>
        <p className={cn(styles.subheadingText, styles.supportedText)}>
          {t('use_the_top')} {renderedList.length} {t('get_crypto_loans')}
        </p>
        <div className={cn(styles.coins, styles.supportedCoins, styles.coinsThreeInRow)}>{renderedListByDefault}</div>
        {!hidden && (
          <ButtonBlue
            className={styles.coinsButton}
            onClick={() => {
              handleMoreCoins(renderedList.slice().splice(countOfVisibleTokens))
              setHidden(true)
            }}
          >{t('more_coins')}</ButtonBlue>
        )}
      </div>
    </div>
  )
}
