import { SESSION_ITEMS } from 'config/constants'

export const saveReferralId = (refId: string): void => {
  if (typeof window !== 'undefined') {
    window.localStorage.setItem(SESSION_ITEMS.referral, refId)
  }
}

export const getReferralId = (): string => {
  if (typeof window !== 'undefined' && window.localStorage) {
    return window.localStorage?.getItem(SESSION_ITEMS.referral) || ''
  }
  return ''
}

export const clearReferralId = (): void => {
  if (typeof window !== 'undefined') {
    window.localStorage.removeItem(SESSION_ITEMS.referral)
  }
}

export const clearCaptchaData = (): void => {
  if (typeof window !== 'undefined') {
    window.localStorage.removeItem(SESSION_ITEMS.grecaptcha)
    window.localStorage.removeItem(SESSION_ITEMS.captchaSession)
  }
}
