import cn from 'classnames'

import IconPhone from './icons/phone.webp'
import styles from './styles.module.scss'
import IconCircle from './icons/circle-icon.svg'
import IconWorld from './icons/world-icon.svg'
import { useTranslation } from 'utils/use-translation'

export default function Notification() {
  const { t } = useTranslation()

  return (
    <div className={styles.block}>
      <div className={cn('container', styles.blockWrapper)}>
        <div className={styles.imageBox}>
          <img src={IconPhone} alt="best bitcoin lending site" className={styles.image} width={559} height={695} />
        </div>
        <div className={styles.content}>
          <h2 className={styles.title}>{t('always_on_watch')}</h2>
          <div className={styles.contentBox}>
            <img
              src={IconWorld}
              alt="Borrow Bitcoin and other cryptocurrencies"
              width={52}
              height={52}
              loading="lazy"
            />
            <p className={styles.contentText}>
              {t('monitor_your_loan')}
            </p>
            <p className={styles.contentText}>
              {t('if_loan_reaches')} <b>{t('three_limit_zones')}</b>, {t('we_will_notify')}
            </p>
          </div>
          <div className={styles.contentBox}>
            <img src={IconCircle} alt="cryptocurrency loans worldwide" width={67} height={67} loading="lazy" />
            <p className={styles.contentText}>
              {t('support_team_ready_to_help')}
            </p>
            <p className={styles.contentText}>
              <b>{t("live_support_247_lower_case")}</b> {t('here_up_for_you')}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
